<template>
    <div>
        <div :class="['navbar-area', { 'is-sticky': isSticky }]">
            <div class="main-navbar navbar-with-black-color">
                <div class="container-fluid">
                    <nav class="navbar navbar-expand-lg navbar-light">
                        <router-link class="navbar-brand" to="/">
                            <img src="../../assets/images/Nortvis-logo-01.png" alt="image"
                            style="width: 180px;">
                        </router-link>

                        <div
                            class="navbar-toggler"
                            @click="active = !active"
                            :aria-pressed="active ? 'true' : 'false'"
                            v-bind:class="{ active: button_active_state }"
                            v-on:click="button_active_state = !button_active_state"
                        >
                            <i class="ri-menu-line"></i>
                            <i class="ri-close-line"></i>
                        </div>

                        <div class="collapse navbar-collapse" :class="{ toggler: active }">
                            <ul class="navbar-nav ms-auto">
                                <li class="nav-item">
                                    <router-link to="/" class="nav-link">
                                        Home 
                                    </router-link>
                                </li>

                                <li class="nav-item">
                                    <router-link to="/services" class="nav-link">
                                        Services
                                    </router-link>
                                </li>

                                <li class="nav-item">
                                    <router-link to="/products" class="nav-link">
                                        Products
                                    </router-link>
                                </li>

                                <li class="nav-item">
                                    <router-link to="/about" class="nav-link">
                                        About
                                    </router-link>
                                </li>


                                <li class="nav-item">
                                    <router-link to="/contact" class="nav-link">
                                        Contact
                                    </router-link>
                                </li>
                            </ul>
                        </div>

                        
                    </nav>
                </div>
            </div>
        </div>
        <div 
            class="sidebarModal modal right"
            v-if="isSideMenu"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <button 
                        type="button" 
                        class="close" 
                        v-on:click="isSideMenuMethod(isSideMenu)"
                    >
                        <i class="ri-close-line"></i>
                    </button>

                    <div class="modal-body">
                        <router-link to="/">
                            <img src="../../assets/images/favicon.png" alt="logo">
                        </router-link>
                        <div class="sidebar-content">
                            <h3>About Us</h3>
                            <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                            <div class="sidebar-btn">
                                <router-link to="/contact" class="default-btn">Let’s Talk</router-link>
                            </div>
                        </div>
                        <div class="sidebar-contact-info">
                            <h3>Contact Information</h3>

                            <ul class="info-list">
                                <li><i class="ri-phone-fill"></i> <a href="tel:9901234567">+990-123-4567</a></li>
                                
                                <li><i class="ri-mail-line"></i> <a href="mailto:koze@gmail.com">koze@gmail.com</a></li>

                                <li><i class="ri-map-pin-line"></i> 413 North Las Vegas, NV 89032</li>
                            </ul>
                        </div>
                        <ul class="sidebar-social-list">
                            <li><a href="https://facebook.com/" target="_blank"><i class="ri-facebook-fill"></i></a></li>
                            <li><a href="https://twitter.com/" target="_blank"><i class="ri-twitter-fill"></i></a></li>
                            <li><a href="https://linkedin.com/" target="_blank"><i class="ri-linkedin-fill"></i></a></li>
                            <li><a href="https://instagram.com/" target="_blank"><i class="ri-instagram-fill"></i></a></li>
                        </ul>
                        <div class="contact-form">
                            <h3>Ready to Get Started?</h3>

                            <form id="contactForm">
                                <div class="row">
                                    <div class="col-lg-12 col-md-6">
                                        <div class="form-group">
                                            <input type="text" name="name" class="form-control" placeholder="Your name">
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-6">
                                        <div class="form-group">
                                            <input type="email" name="email" class="form-control" placeholder="Your email address">
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <input type="text" name="phone_number" class="form-control" placeholder="Your phone number">
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <textarea name="message" class="form-control" cols="30" rows="6" placeholder="Write your message..."></textarea>
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12">
                                        <button type="submit" class="default-btn">Send Message<span></span></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Navbar",
    data() {
        return {
            isSticky: false,
            search: false,
            button_search_state: false,
            active: false,
            button_active_state: false,
            isSideMenu: false,
        };
    },
    mounted() {
        const that = this;
        window.addEventListener("scroll", () => {
            let scrollPos = window.scrollY;
            if (scrollPos >= 100) {
                that.isSticky = true;
            } else {
                that.isSticky = false;
            }
        });
    },
    methods: {
        isSideMenuMethod(isSideMenu){
            return this.isSideMenu = !isSideMenu
        },
    },
    computed: {
        shoppingCart() {
        return this.$store.state.cart;
        },
    },
};
</script>
