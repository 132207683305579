<template>
    <footer class="footer-area with-black-background pt-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-3 col-md-6">
                    <div class="single-footer-widget" data-aos="fade-up" data-aos-delay="50" data-aos-duration="500" data-aos-once="true">
                        <div class="widget-logo">
                            <router-link to="/">
                                <img src="../../assets/images/Nortvis-logo-01.png" alt="image"
                                style="width: 200px">
                            </router-link>
                        </div>
                        <p>4/461, 2nd Floor, Valamkottil Towers, Judgemukku, Thrikkakara P.O, Kakkanad</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="single-footer-widget ps-5" data-aos="fade-up" data-aos-delay="60" data-aos-duration="600" data-aos-once="true">
                        <h3>Links</h3>

                        <ul class="quick-links">
                            <li><router-link to="/about">About Us</router-link></li>
                            <li><router-link to="/services">Services</router-link></li>
                            <li><router-link to="/products">Products</router-link></li>
                            <li><router-link to="/contact">Contact Us</router-link></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 f-res">
                    <div class="single-footer-widget ps-5" data-aos="fade-up" data-aos-delay="70" data-aos-duration="700" data-aos-once="true">
                        <h3>Services</h3>

                        <ul class="quick-links">
                            <li><router-link :to="{path: '/services', hash: '#customer-design'}">User Experience Design</router-link></li>
                            <li><router-link :to="{path: '/services', hash: '#it-consulting'}">IT Consulting</router-link></li>
                            <li><router-link :to="{path: '/services', hash: '#web-app'}">Web Application Development</router-link></li>
                            <li><router-link :to="{path: '/services', hash: '#mobile-app'}">Mobile Application Development</router-link></li>
                            <li><router-link :to="{path: '/services', hash: '#digital'}">Digital Transformation</router-link></li>
                            <li><router-link :to="{path: '/services', hash: '#web-modernising'}">Web Modernising Service</router-link></li>
                            <li><router-link :to="{path: '/services', hash: '#cloud-hosting'}">Cloud Hosting</router-link></li>
                            <li><router-link :to="{path: '/services', hash: '#it-outsourcing'}">IT Outsourcing</router-link></li>
                            <li><router-link :to="{path: '/services', hash: '#it-services'}">IT Services</router-link></li>
                            <li><router-link :to="{path: '/services', hash: '#testing'}">Testing & Quality Assurance</router-link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="copyright-area">
            <div class="container">
                <div class="copyright-area-content">
                    <p>
                        &copy; {{currentYear}} All Rights Reserved by
                        <a target="_blank"> Nortvis Pvt Ltd</a>
                    </p>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer',
    data() {
        return {
            currentYear: new Date().getFullYear(),
        };
    }
}
</script>