<template>
    <div class="choose-area bg-with-F5F5F5-color pt-100 pb-75">
        <div class="container">
            <div class="section-title">
                <span>WHY CHOOSE US</span>
                <h2>We Help You To <b>Increase</b> Your Sale Through Solutions</h2>
                <p>Nortvis are passionate in delivering world-class web solutions that builds 
                    your brand, business and customer relationship.</p>
            </div>

            <div class="row justify-content-center">
                <div class="col-lg-3 col-md-6">
                    <div class="single-choose-card" data-aos="fade-up" data-aos-delay="50" data-aos-duration="500" data-aos-once="true">
                        <div class="choose-image" data-tilt>
                            <router-link :to="{path:'/services', hash: '#webapp'}">
                                <img src="../../assets/images/services/responsive.png" alt="image">
                            </router-link>
                        </div>
                        <div class="choose-content">
                            <h3>
                                <router-link :to="{path:'/services', hash: '#webapp'}">Web Application Development</router-link>
                            </h3>
                            <p>We build bespoke software that work for your business & your clients</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="single-choose-card" data-aos="fade-up" data-aos-delay="60" data-aos-duration="600" data-aos-once="true">
                        <div class="choose-image" data-tilt>
                            <router-link :to="{path:'/services', hash: '#cloud-hosting'}">
                                <img src="../../assets/images/services/cloud-service.png" alt="image">
                            </router-link>
                        </div>
                        <div class="choose-content">
                            <h3>
                                <router-link :to="{path:'/services', hash: '#cloud-hosting'}">Cloud Hosting</router-link>
                            </h3>
                            <br>
                            <p>NORTVIS have the finest experts in cloud computing to take forward your business with Cloud Transformation solution.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="single-choose-card" data-aos="fade-up" data-aos-delay="70" data-aos-duration="700" data-aos-once="true">
                        <div class="choose-image" data-tilt>
                            <router-link to="{path:'/services', hash: '#digital'}">
                                <img src="../../assets/images/services/digital-transformation.png" alt="image">
                            </router-link>
                        </div>
                        <div class="choose-content">
                            <h3>
                                <router-link :to="{path:'/services', hash: '#digital'}">Digital Transformation</router-link>
                            </h3><br>
                            <p>Digitalization and automation are the game changers to meet these challenges on the way to Industry 4.0.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="single-choose-card" data-aos="fade-up" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                        <div class="choose-image" data-tilt>
                            <router-link :to="{path:'/services', hash: '#web-modernising'}">
                                <img src="../../assets/images/services/web-development.png" alt="image">
                            </router-link>
                        </div>
                        <div class="choose-content">
                            <h3>
                                <router-link :to="{path:'/services', hash: '#web-modernising'}">Web Modernising Services</router-link>
                            </h3>
                            <p>Application modernization helps keep legacy apps cost-efficient and relevant to the current business needs.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="choose-shape-1">
            <img src="../../assets/images/choose/choose-shape.png" alt="image">
        </div>
        <div class="choose-shape-2">
            <img src="../../assets/images/choose/choose-shape-2.png" alt="image">
        </div>
    </div>
</template>

<script>
export default {
    name: 'ChooseUs'
}
</script>