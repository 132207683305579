<template>
    <div class="talk-area ptb-100">
        <div class="container">
            <div class="row align-items-center">

                <div class="col-lg-6 inq col-md-12">
                    <div class="talk-content">
                        <span>LET'S TALK</span>
                        <h3>Start An Inquiry</h3>
                        <p>Thank you for your interest in NORTVIS. 
                            Please submit the form below and we will get back to you at the earliest</p>

                        <form id="contactFormTwo">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="name" class="form-control" placeholder="Your name">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="email" name="email" class="form-control" placeholder="Your email address">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea name="message" class="form-control" cols="30" rows="6" placeholder="Write your message..."></textarea>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="default-btn">Send Message<span></span></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Talk'
}
</script>