<template>
    <div class="container ptb-100">
        <div class="map-location">
            <iframe src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=4/461,%202nd%20Floor,%20Valamkottil%20Towers,%20Judgemukku,%20Thrikkakara%20P.O,%20Kakkanad+(Your%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Map'
}
</script>