<template>
    <div class="projects-area with-black-background pb-100">
        <div class="container-fluid">
            <div class="section-title">
                <span>PRODUCTS</span>
                <h2>Our Signature <b style="-webkit-text-fill-color: white;">Products</b></h2>
                <p>We have the best class products stashed with all the premium features, created out 
                    of cutting edge technology to dominate in any complex competitive business environments.</p>
            </div>

            <div class="projects-slides">
                <carousel
                    :autoplay="5000"
                    :wrap-around="true"
                    :settings='settings' 
                    :breakpoints='breakpoints'
                    
                >
                    <Slide v-for="slide in carouselItems" :key="slide.id">
                        <div class="projects-item">
                            <div class="projects-image">
                                <router-link :to="{path: slide.btnLink, hash: slide.hash}">
                                    <img :src="slide.image" alt="image">
                                </router-link>
                            </div>
                            <div class="projects-content">
                                <h3 v-if="slide.id == 4 || slide.id == 1 || slide.id == 2" style="font-size:1rem">
                                    <router-link :to="{path: slide.btnLink, hash: slide.hash}">{{slide.title}}</router-link>
                                </h3>
                                <h3 v-else>
                                    <router-link :to="{path: slide.btnLink, hash: slide.hash}">{{slide.title}}</router-link>
                                </h3>
                                <router-link :to="{path: slide.btnLink, hash: slide.hash}" class="projects-btn">
                                    {{slide.btnText}}
                                </router-link>
                            </div>
                        </div>
                    </Slide>

                    <template #addons>
                        <Navigation />
                    </template>
                </carousel>
            </div>
        </div>

    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide, Navigation  } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'Projects',
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
            pauseAutoplayOnHover: true
        },
        carouselItems: [
            {
                id: 1,
                image: require('../../assets/images/choose/ecommerce.png'),
                title: 'E-Commerce Marketplace',
                btnLink: '/products',
                hash: '#e-commerce',
                btnText: 'View More',
            },
            {
                id: 2,
                image: require('../../assets/images/choose/software.png'),
                title: 'Direct Selling Software',
                btnLink: '/products',
                hash: '#direct-selling',
                btnText: 'View More',
            },
            {
                id: 3,
                image: require('../../assets/images/choose/project.png'),
                title: 'ERP Projects',
                btnLink: '/products',
                hash: '#erp-projects',
                btnText: 'View More',
            },
            {
                id: 4,
                image: require('../../assets/images/choose/franchise-billing.png'),
                title: 'Franchise Billing Software',
                btnLink: '/products',
                hash: '#franchise',
                btnText: 'View More',
            },
            {
                id: 5,
                image: require('../../assets/images/services/it-consulting.png'),
                title: 'IT Consulting',
                btnLink: '/products',
                hash: '#it-consulting',
                btnText: 'View More',
            },
            {
                id: 6,
                image: require('../../assets/images/services/outsource.png'),
                title: 'IT Outsourcing',
                btnLink: '/products',
                hash: '#it-outsourcing',
                btnText: 'View More',
            },
        ],
        breakpoints: {
            0: {
                itemsToShow: 1,
                snapAlign: 'center',
			},
            768: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            1024: {
                itemsToShow: 3.1,
                snapAlign: 'center',
            },
            1200: {
                itemsToShow: 4.1,
                snapAlign: 'center',
            },
        },
    }),
})
</script>