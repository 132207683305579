<template>
    <div class="services-area with-radius ptb-100">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-4 col-md-12">
                    <div class="services-section-content" data-aos="fade-down" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                        <span>SERVICES</span>
                        <h3>We Provide the Best Quality <b>Services</b></h3>
                        <p>Nortvis are passionate in delivering 
                            world-class cutting-edge technology solutions that builds your brand, business and customer 
                            relationship.</p>
                        <div class="services-section-btn">
                            <router-link to="/services" class="default-btn">
                                Explore All Services
                            </router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-8 col-md-12">
                    <div class="services-slides">
                        <carousel
                            :autoplay="5000"
                            :wrap-around="true"
                            :settings='settings' 
                            :breakpoints='breakpoints'
                        >
                            <Slide v-for="slide in carouselItems" :key="slide.id">
                                <div class="services-item">
                                    <div class="image-holder">
                                        <div class="services-image">
                                            <router-link :to="{path: slide.link, hash: slide.hash}">
                                                <img :src="slide.image" alt="image">
                                            </router-link>
                                        </div>
                                    </div>
                                    <div class="services-content">
                                        <h3>
                                            <router-link :to="{path: slide.link, hash: slide.hash}">{{slide.title}}</router-link>
                                        </h3>
                                        <p style="text-align:left">{{slide.desc}}</p>
                                        <router-link :to="{path: slide.link, hash: slide.hash}" class="services-btn">
                                            View More
                                        </router-link>
                                    </div>
                                </div>
                            </Slide>

                            <template #addons>
                                <Pagination />
                            </template>
                        </carousel>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide, Pagination  } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'Services',
    components: {
        Carousel,
        Slide,
        Pagination,
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
            pauseAutoplayOnHover: true
        },
        carouselItems: [
            {
                id: 1,
                image: require('../../assets/images/services/responsive.png'),
                title: 'Web Application Development',
                desc: 'We build bespoke software that work for your business & your clients',
                link: '/services',
                hash: '#web-app',
                btnText: 'View More',
            },
            {
                id: 2,
                image: require('../../assets/images/services/cloud-service.png'),
                title: 'Cloud Hosting',
                desc: 'NORTVIS have the finest experts in cloud computing to take forward your business with Cloud Transformation solution. ',
                link: '/services',
                hash: '#cloud-hosting',
                btnText: 'View More',
            },
            {
                id: 3,
                image: require('../../assets/images/services/web-development.png'),
                title: 'Web Modernising Services',
                desc: 'Application modernization helps keep legacy apps cost-efficient and relevant to the current business needs.',
                link: '/services',
                hash: '#web-modernising',
                btnText: 'View More',
            },
            {
                id: 4,
                image: require('../../assets/images/services/digital-transformation.png'),
                title: 'Digital Transformation',
                desc: 'Digitalization and automation are the game changers to meet these challenges on the way to Industry 4.0.',
                link: '/services',
                hash: '#digital',
                btnText: 'View More',
            },
            {
                id: 5,
                image: require('../../assets/images/services/outsource.png'),
                title: 'IT Outsourcing',
                desc: 'We provide best IT outsourcing solutions to cater to the requirements of the clients.',
                link: '/services',
                hash: '#it-outsourcing',
                btnText: 'View More',
            },
            {
                id: 6,
                image: require('../../assets/images/services/it-consulting.png'),
                title: 'IT Consulting',
                desc: 'We provide the most credible IT consulting services to business & enterprises of all levels.',
                link: '/services',
                hash: '#it-consulting',
                btnText: 'View More',
            },
            {
                id: 7,
                image: require('../../assets/images/services/technical-support.png'),
                title: 'Managed IT Services',
                desc: 'Managed IT Services will help in meeting your business as well as your client’s needs.',
                link: '/services',
                hash: '#it-services',
                btnText: 'View More',
            },
            {
                id: 8,
                image: require('../../assets/images/services/test.png'),
                title: 'Testing',
                desc: 'We provide the cutting-edge testing solutions with our seasoned & expert QA professionals.',
                link: '/services',
                hash: '#testing',
                btnText: 'View More',
            },
            {
                id: 9,
                image: require('../../assets/images/services/user-interface.png'),
                title: 'Mobile App Development',
                desc: 'We develop potential mobile apps to provide your users with everything they are looking for.',
                link: '/services',
                hash: '#mobile-app',
                btnText: 'View More',
            },
            {
                id: 10,
                image: require('../../assets/images/services/digital-campaign.png'),
                title: 'User Experience Design',
                desc: 'We design client businesses to be customer-centric, growth-focused, and one-off in their category.',
                link: '/services',
                hash: '#customer-design',
                btnText: 'View More',
            },
        ],
        breakpoints: {
            0: {
                itemsToShow: 1,
                snapAlign: 'center',
			},
            768: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            1024: {
                itemsToShow: 3,
                snapAlign: 'center',
            },
            1200: {
                itemsToShow: 3,
                snapAlign: 'center',
            },
        },
    }),
})
</script>