<template>
    <div class="overview-area pt-100 pb-75">
        <div class="container">
            <div class="row justify-content-center">

                <div class="col-lg-3 col-md-6">
                    <div class="overview-card">
                        <h3>Email Us</h3>
                        <span>
                            <a href="mailto:info@nortvis.com">info@nortvis.com</a>
                        </span>

                        <div class="overview-shape">
                            <img src="../../assets/images/overview/overview-shape.png" alt="image">
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="overview-card">
                        <h3>Business Enquiry</h3>
                        <span>
                            <a href="tel:9895190515">+91 9895 190 515</a>
                        </span>

                        <div class="overview-shape">
                            <img src="../../assets/images/overview/overview-shape.png" alt="image">
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="overview-card">
                        <h3>Visit Us</h3>
                        <span>4/461, 2nd Floor, Valamkottil Towers, Judgemukku, Thrikkakara P.O, Kakkanad</span>

                        <div class="overview-shape">
                            <img src="../../assets/images/overview/overview-shape.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Overview'
}
</script>