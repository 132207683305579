<template>
    <div class="topbar-area">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-9 b col-md-9">
                    <ul class="topbar-information">
                        <li>
                            <i class="ri-phone-line"></i>
                            <span>Phone: <a href="tel:9895190515">+91 9895 190 515</a></span>
                        </li>
                        <li>
                            <i class="ri-mail-line"></i>
                            <span>Mail: <a href="mailto:info@nortvis.com">info@nortvis.com</a></span>
                        </li>
                        <li id="top-address">
                            <i class="ri-map-pin-line"></i>
                            <span>Address:</span>Valamkottil Towers, Judgemukku, Thrikkakara P.O, Kakkanad
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Topbar',
	data: () => ({
		show: false,
		active: false,
        button_show_state: false,
		newData: [
            {
                id: '',
                image: require('../../assets/images/uk.png'),
                name: 'ENG',
            },
            {
                id: '',
                image: require('../../assets/images/china.png'),
                name: '简体中文',
            },
            {
                id: '',
                image: require('../../assets/images/uae.png'),
                name: 'العربيّة',
            }
        ],
		name: "ENG",
		image: require("../../assets/images/uk.png"),
        clicked: false
	}),

    methods: {
        toggleSelected(select, index){
            if(this.clicked === index){
                this.clicked = null
            }

            this.clicked = index 
            this.name = select.name 
            this.image = select.image
        }
    }
}
</script>