<template>
    <div class="choose-area pt-100 pb-75">
        <div class="container">
            <div class="section-title">
                <span>WHY CHOOSE US</span>
                <h2>Our <b>Purpose</b></h2>
                <p>We are the best because we have the best expertise & technology with us to deliver you the desired results. Whether you want to develop a modern end-to-end web 
                    and mobile applications for your business say e-commerce, technology, lifestyle or anything else you wish, our team is here to help you from beginning till the end.</p>
            </div>

            <div class="row">
                <div class="col-lg-3 col-sm-6">
                    <div class="single-choose-card" data-aos="fade-up" data-aos-delay="50" data-aos-duration="500" data-aos-once="true">
                        <div class="choose-content">
                            <h3>
                                <a>We Have a Team of Specialists</a>
                            </h3>
                            <p>We have an exquisite team of specialists to help you in transforming your business 
                                ideas using the best tools based on Requirements, Size, Time & Budget of your Project.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="single-choose-card" data-aos="fade-up" data-aos-delay="60" data-aos-duration="600" data-aos-once="true">
                        <div class="choose-content">
                            <h3>
                                <a>Deep Research Oriented Strategies</a>
                            </h3>
                            <p>We use the best strategies & solutions for acquiring New customers as well as 
                                retaining Existing customers with continuous improvements through Data Intelligence.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="single-choose-card" data-aos="fade-up" data-aos-delay="70" data-aos-duration="700" data-aos-once="true">
                        <div class="choose-content">
                            <h3>
                                <a>We Are Result Oriented</a>
                            </h3>
                            <p>We genuinely care about the impact of our product on your business organisation , 
                                therefore we are deeply focus on delivering the best possible solutions for your end-users.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="single-choose-card" data-aos="fade-up" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                        <div class="choose-content">
                            <h3>
                                <a>Your Most Credible Partner</a>
                            </h3>
                            <p>We assure you the State-of-the-art Software Solutions, Bespoke Security, 
                                Customer friendly Services & Support to amply your business with quality & excellence</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="choose-shape-1">
            <img src="../../assets/images/choose/choose-shape.png" alt="image">
        </div>
        <div class="choose-shape-2">
            <img src="../../assets/images/choose/choose-shape-2.png" alt="image">
        </div>
    </div>
</template>

<script>
export default {
    name: 'ChooseUs'
}
</script>