<template>
    <div class="about-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="about-image" data-tilt>
                        <img src="../../assets/images/about/about-7.svg" alt="image" data-aos="fade-down" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="about-content" data-aos="fade-up" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                        <span>WHO WE ARE</span>
                        <h3>We Are <b>All In One</b> IT Solution & Technology Company</h3>
                        <p>Since 2019. For millions of users. We transform businesses with powerful and adaptable 
                            digital solutions that satisfy the needs of today and unlock the opportunities of tomorrow.</p>
                        <p>Are you looking for an IT strategy that flexibly adapts to your business requirements and thus protects 
                            your previous investments? With Next Level Business IT from MODUS Consult, you and your 
                            colleagues work completely intuitively and connected in your industry.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="about-shape-1">
            <img src="../../assets/images/about/about-shape.png" alt="image">
        </div>
    </div>
</template>

<script>
export default {
    name: 'About'
}
</script>