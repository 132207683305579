<template>
    <div class="services-area ptb-100">
        <div class="container">
            <div class="section-title">
                <span>SERVICES</span>
                <h2>We Provide the Best Quality <b>Services</b></h2>
                <p>Nortvis are passionate in delivering world-class web solutions that builds your brand, 
                    business and customer relationship.</p>
            </div>

            <div class="row justify-content-center">
                <div class="col-lg-4 c col-md-6" id="web-app">
                    <div class="services-item" style="height: 96%">
                        <div class="image-holder">    
                            <div class="services-image serv-img">
                                <a>
                                    <img src="../../assets/images/services/responsive.png" alt="image">
                                </a>
                            </div>
                        </div>
                        <div class="services-content">
                            <h3>
                                <a>Web Application Development</a>
                            </h3>
                            <p>We build bespoke software that work for your business & your clients. We made use of the 
                                ingenious approaches to Web development for bringing the front-end, 
                                back-end & architecture together to meet your business needs. 
                                We follow a web development strategy where we not only look at your present 
                                business footprints but the future footsteps as well. So NORTVIS’s Web development team 
                                will help to create the best technology roadmap to achieve your desired objectives.</p>
                            
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 c  col-md-6" id="digital">
                    <div class="services-item" style="height: 96%">
                        <div class="image-holder">    
                            <div class="services-image serv-img">
                                <a>
                                    <img src="../../assets/images/services/digital-transformation.png" alt="image">
                                </a>
                            </div>
                        </div>
                        <div class="services-content">
                            <h3>
                                <a>Digital Transformation</a>
                            </h3>
                            <p>The industrial world is facing rapidly changing challenges. Our resources are finite, and 
                                we all need to do more with less. Digitalization and automation are the game changers to 
                                meet these challenges on the way to Industry 4.0. The Digital Enterprise is doing exactly this by 
                                combining the real and the digital worlds. As a result, the infinite amount of data allows us to 
                                use our finite resources efficiently and with that make the industry more sustainable. With NORTVIS, 
                                you can realize the digital transformation in your company, too. Contact us – our experts will 
                                happily support you.</p>
                            
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 c  col-md-6" id="it-outsourcing">
                    <div class="services-item" style="height: 96%">
                        <div class="image-holder">    
                            <div class="services-image serv-img">
                                <a>
                                    <img src="../../assets/images/services/outsource.png" alt="image">
                                </a>
                            </div>
                        </div>
                        <div class="services-content">
                            <h3>
                                <a>IT Outsourcing</a>
                            </h3>
                            <p>We provide best IT outsourcing solutions to cater the requirements of the clients. 
                                You can rely on us, as your in-house team as we work with full transparency & take 
                                responsibility for the services we deliver. We can help to setup a software Outsourcing 
                                team for any kind of technological development & get access to the leading tech crew in 
                                India. We will help you to find the best talented ones at a reasonable cost. With a team 
                                of professionals we have managed to overcome the linguistic, physical, geographic & 
                                cultural barriers to communicate across the different stages of software development and
                                 manage the inter-team relation.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 c  col-md-6" id="it-consulting">
                    <div class="services-item" style="height: 96%">
                        <div class="image-holder">    
                            <div class="services-image serv-img">
                                <a>
                                    <img src="../../assets/images/services/it-consulting.png" alt="image">
                                </a>
                            </div>
                        </div>
                        <div class="services-content">
                            <h3>
                                <a>IT Consulting</a>
                            </h3>
                            <p>We provide the most credible IT consulting services to business & enterprises of all levels. 
                                Our IT consulting experts will help you to develop the most inventive solutions 
                                and strategies which will help in making the most of the investments in technology to 
                                gain a leading edge. Our experts are equipped to handle your projects & strategies with 
                                tailor-made solutions for all your business needs. Also we work by merging industry’s best 
                                practices and expertise to help you secure the maximum benefits. With us, you will have a better
                                 understanding of the emerging technologies, best design practices, with a reliable & flexible 
                                 strategy to achieve sustained results.</p>
                            
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 c  col-md-6" id="it-services">
                    <div class="services-item" style="height: 96%">
                        <div class="image-holder">    
                            <div class="services-image serv-img">
                                <a>
                                    <img src="../../assets/images/services/technical-support.png" alt="image">
                                </a>
                            </div>
                        </div>
                        <div class="services-content">
                            <h3>
                                <a>Managed IT Services</a>
                            </h3>
                            <p>Managed IT Services will help to keep your employees more productive, potent and 
                                engaged on meeting your business as well as your clients’ needs. NORTVIS provides the 
                                best people, processes and technologies to support your IT environment whether you 
                                have no IT team, a small team or an in-house full IT department. Our flexible and agile 
                                solutions will optimize technology for sustainable business growth and profitability, 
                                empowering you to go where you want to grow. Our proficient team of IT professionals and 
                                full-service suite of IT solutions are available round the clock to ensure your success.</p>
                            
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 c  col-md-6" id="web-modernising">
                    <div class="services-item" style="height: 96%">
                        <div class="image-holder">    
                            <div class="services-image serv-img">
                                <a>
                                    <img src="../../assets/images/services/web-development.png" alt="image" id="web-image">
                                </a>
                            </div>
                        </div>
                        <div class="services-content">
                            <h3>
                                <a>Web Modernising Services</a>
                            </h3>
                            <p>Application modernization helps keep legacy apps cost-efficient and relevant to the current business needs.
                                Whether your existing website is simple, a special event site, or a world class website to engage your 
                                customers in every fashion that is necessary for your business (e.g. blogging, e-commerce store, 
                                merchant credit card integration, content creation, etc.), then look no further.
                                We offer best-in-class Application Modernization Services for organizations to conduct 
                                legacy transformation in order to transform their legacy systems to newer technologies.</p>
                            
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 c col-md-6" id="mobile-app">
                    <div class="services-item" style="height: 96%">
                        <div class="image-holder">    
                            <div class="services-image serv-img">
                                <a>
                                    <img src="../../assets/images/services/user-interface.png" alt="image">
                                </a>
                            </div>
                        </div>
                        <div class="services-content">
                            <h3>
                                <a>Mobile Application Development</a>
                            </h3>
                            <p>Mobile applications have proven to be quick and effective in engaging customer interest and 
                                communicating product launches, new services, promotional offers, improved features and discounted rates. 
                                We develop potential mobile apps to provide 
                                your users with everything they are looking for. 
                                Unique features, appealing UI/UX, and smart assistance can help your business in different 
                                segments to grab never-ending opportunities.</p>
                            
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 c col-md-6" id="cloud-hosting">
                    <div class="services-item" style="height: 96%">
                        <div class="image-holder">    
                            <div class="services-image serv-img">
                                <a>
                                    <img src="../../assets/images/services/cloud-service.png" alt="image">
                                </a>
                            </div>
                        </div>
                        <div class="services-content">
                            <h3>
                                <a>Cloud Hosting</a>
                            </h3>
                            <p>NORTVIS have the finest experts in cloud computing to take forward your business with 
                                Cloud Transformation solution - a business first approach to leverage the cloud technology. 
                                We also collaborate with trusted partners to provide bespoke cloud solutions that help 
                                companies & organizations with hybrid infrastructure, collocation, backups and full migrations.</p>
                            
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 c col-md-6" id="testing">
                    <div class="services-item" style="height: 96%">
                        <div class="image-holder">    
                            <div class="services-image serv-img">
                                <a>
                                    <img src="../../assets/images/services/test.png" alt="image">
                                </a>
                            </div>
                        </div>
                        <div class="services-content">
                            <h3>
                                <a>Testing & QA</a>
                            </h3>
                            <p>We provide the cutting-edge testing solutions with our seasoned & expert QA professionals. 
                                Our quality analysts work with your team to identify, fix, and prevent software quality issues. 
                                We offer a broad spectrum of software testing & QA services such as Automation testing, Manual testing, 
                                Performance testing, Code Verification, Security testing & Compatibility Testing to help our clients 
                                to deliver a flawless & optimally performing software to their users</p>
                            
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 c col-md-6" id="customer-design">
                    <div class="services-item" style="height: 96%">
                        <div class="image-holder">    
                            <div class="services-image serv-img">
                                <a>
                                    <img src="../../assets/images/services/digital-campaign.png" alt="image">
                                </a>
                            </div>
                        </div>
                        <div class="services-content">
                            <h3>
                                <a>User Experience Design</a>
                            </h3>
                            <p>Customer experience design is the process of designing a frictionless experience for the customer 
                                anytime they interact with a point of contact at an organization. It’s centered on improving 
                                processes that meet customers' expectations and are easy and intuitive for them to follow and solve 
                                their problems.We design client businesses to be customer-centric, growth-focused, and one-off in their category. 
                                Using data to understand customers, what they do, why, and how they do it, we align business 
                                experiences to support customer motives.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Services'
}
</script>