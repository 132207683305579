<template>
    <div class="choose-area pt-100 pb-75">
        <div class="container">
            <div class="section-title">
                <span>KEY FEATURES</span>
                <h2>Our <b>Products</b></h2>
                <p>We have the best class products stashed with all the premium features, 
                    created out of cutting edge technology to dominate in any complex competitive 
                    business environments. Our specialists are focused to provide you the finest 
                    bespoke technological solutions to enchant more and more customers to your business.</p>
            </div>

            <div class="row">
                <div class="col-lg-3 c col-sm-6" id="e-commerce">
                    <div class="single-choose-card" data-aos="fade-up" data-aos-delay="50" data-aos-duration="500" data-aos-once="true">
                        <div class="choose-image" data-tilt>
                            <a>
                                <img src="../../assets/images/choose/ecommerce.png" alt="image">
                            </a>
                        </div>
                        <div class="choose-content">
                            <h3>
                                <a>E-Commerce Marketplace</a>
                            </h3>
                            <p>Are you planning to start an e-commerce business? Then we are here to transform your vision 
                                into a successful business venture. We design, develop and deliver the leading edge 
                                e-commerce websites packed with all the advanced features to successfully set up an 
                                e-commerce business.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 c col-sm-6" id="direct-selling">
                    <div class="single-choose-card" data-aos="fade-up" data-aos-delay="60" data-aos-duration="600" data-aos-once="true">
                        <div class="choose-image" data-tilt>
                            <a><img src="../../assets/images/choose/software.png" alt="image"></a>
                        </div>
                        <div class="choose-content">
                            <h3>
                                <a>Direct Selling Software</a>
                            </h3>
                            <p>Want to start your Direct Selling business with the finest Directing Marketing Software 
                                ever? We have the top specialists pioneered in Direct Marketing Software Development 
                                equipped with the best tools. We offer you the most advanced direct marketing software 
                                with all the premium features & the required expertise to manage it on your own.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 c col-sm-6" id="erp-projects">
                    <div class="single-choose-card" data-aos="fade-up" data-aos-delay="70" data-aos-duration="700" data-aos-once="true">
                        <div class="choose-image" data-tilt>
                            <a><img src="../../assets/images/choose/project.png" alt="image"></a>
                        </div>
                        <div class="choose-content">
                            <h3>
                                <a>ERP Projects</a>
                            </h3>
                            <p>If you have a business, you will need an enterprise planning resource system, and in case 
                                you don't know how, then we are here to help you. With us you can make better and smarter
                                business decisions</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 c col-sm-6" id="franchise">
                    <div class="single-choose-card" data-aos="fade-up" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                        <div class="choose-image" data-tilt>
                            <a><img src="../../assets/images/choose/franchise-billing.png" alt="image"></a>
                        </div>
                        <div class="choose-content">
                            <h3>
                                <a>Franchise Billing Software</a>
                            </h3>
                            <p>Centralised real time franchise data using NORTVIS's franchise management software, 
                                franchisors can oversee all their franchisees data sets like billing, revenue collected, 
                                customer information and taxation. Real time updates help you track growth and also identify 
                                underperforming franchises. The Nortvis's software gives you a detailed top level view of the 
                                financial performance of all your franchises and helps manage your network.</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 c col-sm-6" id="it-consulting">
                    <div class="single-choose-card" data-aos="fade-up" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                        <div class="choose-image" data-tilt>
                            <a><img src="../../assets/images/services/it-consulting.png" alt="image"></a>
                        </div>
                        <div class="choose-content">
                            <h3>
                                <a>IT Consulting</a>
                            </h3>
                            <p>Are you planning to level-up your business? Then our experts will help you to develop 
                                the most inventive solutions and strategies which will help in making the most out 
                                of the investments in technology to gain a leading edge. With us, you will have a 
                                better understanding of the emerging technologies, best design practices, with a reliable 
                                & flexible strategy to achieve sustained results.</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 c col-sm-6" id="it-outsourcing">
                    <div class="single-choose-card" data-aos="fade-up" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                        <div class="choose-image" data-tilt>
                            <a><img src="../../assets/images/services/outsource.png" alt="image"></a>
                        </div>
                        <div class="choose-content">
                            <h3>
                                <a>IT Outsourcing</a>
                            </h3>
                            <p>We provide the best IT outsourcing solutions to cater your requirements. You can rely on us, as 
                                your in-house team as we work with full transparency & take responsibility for the services we 
                                deliver. We can help you to setup a Software Outsourcing team for any kind of technological 
                                development & get access to the leading tech crew in India at a reasonable cost.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="choose-shape-1">
            <img src="../../assets/images/choose/choose-shape.png" alt="image">
        </div>
        <div class="choose-shape-2">
            <img src="../../assets/images/choose/choose-shape-2.png" alt="image">
        </div>
    </div>
</template>

<script>
export default {
    name: 'ChooseUs'
}
</script>