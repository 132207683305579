<template>
    <div class="about-area pt-100 pb-75">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="about-wrap-image" data-tilt>
                        <img src="../../assets/images/about/about-6.svg" alt="image" data-aos="fade-down" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="about-wrap-content" data-aos="fade-up" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                        <span>WHO WE ARE</span>
                        <h3>Your Trusted Partner For All IT Solutions</h3>
                        <p>We assure you the State-of-the-art Software Solutions, Bespoke Security, Customer 
                            friendly Services & Support to amplify your business with quality & excellence</p>
                    </div>
                </div>
            </div>

            <div class="about-inner-box">
                <div class="row justify-content-center">
                    <div class="col-lg-4 col-md-6">
                        <div class="single-about-card" data-aos="fade-up" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                            <h3>Our Vision</h3>
                            <p>"Co-creating brand differentiation for our clients and to be the premier IT solutions 
                                company providing end to end solutions in a supportable manner."</p>

                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="single-about-card" data-aos="fade-down" data-aos-delay="70" data-aos-duration="700" data-aos-once="true">
                            <h3>Our Mission</h3>
                            <p>"Be passionate about client's success and provide excellence to transform customer's 
                                business to a modern era through IT innovations."</p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="single-about-card" data-aos="fade-up" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                            <div class="card-image" data-tilt>
                                <img src="../../assets/images/about/about-8.svg" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="about-circle-shape">
            <img src="../../assets/images/about/about-circle.png" alt="image">
        </div>
    </div>
</template>

<script>
export default {
    name: 'AboutTwo'
}
</script>